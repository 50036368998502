import React from "react";

const Blogs = () => {
  return (
    <>
      <div className="container">
        <h5 className="text-bg-warning mt-3 py-1 px-1"> Blogs </h5>
        <br />
        <br />
        <div class="row">
          <div class="col-12">
            <h2>Overcoming Crane Grab Failures: </h2>
            <p class="lead">
              A ShipSmith Success Story In the bustling world of cargo shipping,
              the reliability of equipment is paramount. Among the vital
              machinery on a cargo ship, the crane grab is crucial for efficient
              loading and unloading operations. Any malfunction with this
              component can lead to significant disruptions, causing delays and
              financial setbacks.
            </p>

            <h2 class="mt-5">The Crew's Initial Response</h2>
            <p>
              Recently, a cargo ship docked at Mumbai Port encountered a severe
              issue with one of its crane grabs. The grab, which is essential
              for handling cargo, was not closing properly. This forced the crew
              to depend on a single grab for the unloading process, which
              significantly slowed down their operations.
            </p>
            <p>
              The ship's crew acted swiftly to resolve the issue. They began by
              replacing the grab's battery and antenna with spare parts, hoping
              to rectify the malfunction. Despite their efforts, the grab
              continued to malfunction, refusing to close properly. They then
              removed, cleaned, and refitted the solenoid valve, yet the problem
              persisted. All electrical and mechanical components appeared to be
              in good condition, which added to their frustration.
            </p>
            <p>
              In a bid to find a solution, the crew decided to interchange the
              solenoid valve from grab number 1 with that of grab number 2. This
              temporary fix worked for about a day and a half, with grab number
              2 functioning correctly. However, the issue reappeared, and grab
              number 2 failed to close despite multiple attempts to purge air
              from the hydraulic system.
            </p>
            <p>
              The crew reverted the solenoid valve to grab number 1, and it was
              confirmed to be functional, as was the throttle valve. They also
              inspected the logic valve and liner, finding them to be in good
              condition. Despite replacing the solenoid valve and purging the
              hydraulic system, grab number 2 remained inoperative.
            </p>
            <h2 class="mt-5">ShipSmith’s Expert Intervention</h2>
            <p>
              Realizing they were out of their depth, the ship's management
              reached out to ShipSmith for urgent assistance. In harsh weather
              conditions, ShipSmith dispatched a team to the site with
              impressive speed—our team of two skilled technicians and one
              engineer arrived on the scene within 12 hours.
            </p>
            <p>
              Our team quickly assessed the situation. Our engineer suspected a
              malfunctioning hydraulic ram and identified oil leakage as a
              contributing factor to the issue. This diagnosis set the stage for
              a focused and effective repair strategy.
            </p>
            <h2 class="mt-5">The Repair Process</h2>
            <p>
              With determination and expertise, our team embarked on the repair
              process:
            </p>

            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <strong>Overhauling the Hydraulic Ram:</strong> One of our
                technicians, under the guidance of our engineer, overhauled the
                hydraulic ram. This involved disassembling the component to
                address internal issues.
              </li>
              <li class="list-group-item">
                <strong>Replacing Faulty Components:</strong> The faulty sealing
                ring and O-ring were replaced. These components were crucial for
                maintaining proper hydraulic pressure and preventing leakage.
              </li>
              <li class="list-group-item">
                <strong>Fabricating Tools:</strong> To address the rusty and
                jammed cylinder, we fabricated the necessary tools. Utilizing
                the owner’s spares, we renewed the seal to ensure optimal
                performance.
              </li>
              <li class="list-group-item">
                <strong>Reassembling and Testing:</strong> The hydraulic ram was
                meticulously reassembled and refitted into the crane. We
                conducted thorough testing to ensure the grab's functionality
                was fully restored.
              </li>
            </ul>

            <p class="mt-4">
              Our efforts were successful—the crane grab was operational once
              again, thanks to the precision and efficiency of our team.
            </p>

            <h2 class="mt-5">Delivering Results Under Adverse Conditions</h2>
            <p>
              Despite the challenging conditions and tight timeframe,
              ShipSmith’s intervention ensured that the crane grab was back in
              working order. Our client was satisfied with the rapid and
              effective resolution of the issue.
            </p>
            <p>
              At ShipSmith, we pride ourselves on being a reliable partner for
              emergency repairs and maintenance. With over 50 years of
              collective experience, our team is adept at handling mechanical,
              hydraulic, and fitting requirements under adverse conditions. We
              operate in all major ports including India, Singapore, China,
              Japan, Dubai, Egypt, Spain, and Gibraltar.
            </p>
            <p class="mb-5">
              Choose ShipSmith for unparalleled expertise and exceptional
              service in the shipping industry. Whether it’s a critical repair
              or routine maintenance, we are committed to ensuring the optimal
              performance of your equipment, even in the most challenging
              situations.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
