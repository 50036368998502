import React from "react";
import logo from "../../assests/logo/ShipSmith Logo Black.png";
import { NavLink } from "react-router-dom";
import "./index.css";
import List from "../list";
import data from "../../assests/data/data";
import { FaYoutube } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoLinkedin } from "react-icons/io5";
import { AiOutlineBehance } from "react-icons/ai";
import Button from "../button";

const Footer = () => {
  let links = [];
  let social_icons = [
    {
      icon: (
        <a
          target="_blank"
          href="https://www.linkedin.com/company/ship-smith/"
          className="text-black"
        >
          <IoLogoLinkedin className="be" size={30} />
        </a>
      ),
    },
    {
      icon: (
        <a
          target="_blank"
          href="https://youtu.be/4eLTzFx_rVw?feature=shared"
          className="text-black"
        >
          <FaYoutube className="be" size={30} />
        </a>
      ),
    },
    {
      icon: (
        <a
          target="_blank"
          href="https://www.instagram.com/shipsmith.in?igsh=MTllbjhod2N1Nzh2bQ=="
          className="text-black"
        >
          <AiFillInstagram className="be" size={30} />
        </a>
      ),
    },
    // {
    //   icon: <AiOutlineBehance className="be" size={30} />,
    // },
  ];
  return (
    <div>
      <div className="container-fluid bg-white nn">
        <div className="container">
          <div className="row">
            <div className="col-sm-4 footertext">
              <div className="footertxtinr">
                <NavLink to={"/"}>
                  <img alt="" src={logo} className=" lll shiplogo mb-4" />
                </NavLink>
                <p className="parte text-black">
                  In our voyage to excellence, we bring forth a team of highly
                  skilled and experienced maritime professionals, dedicated to
                  delivering unparalleled ship repair and comprehensive services
                  tailored to your unique needs.
                </p>
                <div className="social d-flex align-items-center">
                  {social_icons.map((val) => {
                    return <div className="iconss mx-1">{val.icon}</div>;
                  })}
                </div>
              </div>
            </div>
            <div className="col-sm-4 imp">
              <List heading="Important Links" data={data.footerLinks} />
            </div>
            <div className="col-sm-4 p-5 mt-4">
              <h4 className="lettr">News letter</h4>
              <div className="bordered">
                <input
                  className="inputt"
                  type="email"
                  placeholder="Enter your  email..."
                  style={{ padding: "4px", width: "100%" }}
                />
                <Button
                  className="btn_login"
                  text="uppercash"
                  bg="#FFA500"
                  color="black"
                  padding="8px"
                >
                  Submit
                </Button>
              </div>
              <div className="contct">
                <h1 className=" lettr mt-4">Contact</h1>
                <p className="addd">
                  <b> Address:-</b>
                  ShipSmith Services Pvt Ltd, 6WS8D Mani Casadona, Street No
                  372, Action Area II, New Town, Kolkata, West Bengal 700156{" "}
                </p>
                <p className="addd">
                  <b>Call Us:-</b> +91-9651679647 / +91-8130635925
                </p>
                <p className="addd">
                  <b>Mail:-</b>{" "}
                  <a href="mailto:info@shipsmith.in">info@shipsmith.in </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
