import React from "react";
import { RiFontSize } from "react-icons/ri";
import Carousel from "react-multi-carousel";
// import image1 from "../../assests/images/banner/BlueWhale.svg";
// import image2 from "../../assests/images/banner/Frontline.svg";
// import image3 from "../../assests/images/banner/SevenIsland.svg";
// import image4 from "../../assests/images/clients/Garden reach ship builders and engineers ( GRSE).svg";
// import image5 from "../../assests/images/clients/Hooghly cochin shipyard limited.svg";
// import image6 from "../../assests/images/clients/Ryfine, China.svg";
// import image7 from "../../assests/images/clients/Samships.svg";
// import image8 from "../../assests/images/clients/Synergy shipping.svg";
// import image9 from "../../assests/images/clients/Tamar ship management and groups..svg";
// import image10 from "../../assests/images/clients/Udupi cochin shipyard limited.svg";
// import image11 from "../../assests/images/clients/Arka ship management..svg";
// import image12 from "../../assests/images/clients/Cochin shipyard limited.svg";
// import image13 from "../../assests/images/clients/Fleet management..svg";
// import image14 from "../../assests/images/clients/Frontline.png";

const Multicarousel = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  let images = [
    // {
    //   img: image1,
    // },
    // {
    //   img: image2,
    // },
    // {
    //   img: image3,
    // },
    // {
    //   img: image4,
    // },
    // {
    //   img: image5,
    // },
    // {
    //   img: image6,
    // },
    // {
    //   img: image7,
    // },
    // {
    //   img: image8,
    // },
    // {
    //   img: image9,
    // },
    // {
    //   img: image10,
    // },
    // {
    //   img: image11,
    // },
    // {
    //   img: image12,
    // },
    // {
    //   img: image13,
    // },
    // {
    //  img: image14,
    // }

    { name: "Torm Shipping" },
    { name: "Anglo-Ardmore" },
    { name: "MSC Ship Mgmt" },
    { name: "Proactive Ship Mgmt" },
    { name: "Fleet Management" },
    { name: "Arka Global Maritime Services" },
    { name: "Larsen & Toubro" },
    { name: "Cochin Shipyard" },
    { name: "Sinotech Marine" },
    { name: "Buena Vista Shipping" },
    { name: "Tamar Ship Management" },
    { name: "Ryfine Ship Service" },
    { name: "Udupi Cochin Shipyard" },
    { name: "Hooghly Cochin Shipyard Limited" },
    { name: "Garden Reach Shipbuilders & Engineers" },
    { name: "CIDO Shipping" },
    { name: "Seaways Ship Mgmt" },
    { name: "Rhine Marine" },
    { name: "Navios Maritime" },
    { name: "Galena Ship Mgmt" },
    { name: "Nautical Solutions" },
    { name: "Maryam Shipping" },
    { name: "Chellaram Shipping" },
    { name: "Five Star Shipping" },
    { name: "Kaizen Ship Mgmt" },
    
  ];
  return (
        <div className="container mt-5 logoclient">
          <div className="row mb-4">
            <div className="col-sm-12 text-center">
              <h5 className="text-white">Our Clients</h5>
            </div>
          </div>
          <Carousel
            responsive={responsive}
            arrows={false}
            className="text-center"
            showDots={false}
            autoPlay={true}
            autoPlaySpeed={2000}
            infinite={true}
          >
            {images.map((val) => (
              // <img src={val.img} alt="" height={40} />
              <li style={{fontSize:"22px", fontFamily:"monospace",color:"rgba(200,200,255,0.9"}}>{val.name}</li>
            ))}
          </Carousel>
        </div>
    
  );
};

export default Multicarousel;
