import React from 'react'
import service from "../../assests/data/service";
import Servicepage from "../../components/servicespages";

const ThicknessMeasurementOfShips = () => {
  console.log(service)
  return (
    <div>
    <Servicepage
      bannerheading={service.Thickness_Measurement_Of_Ships.bannerheading1}
      bannerheading2={service.Thickness_Measurement_Of_Ships.bannerheading2}
      bannerdescription={service.Thickness_Measurement_Of_Ships.bannerdescription}
      pageheading={service.Thickness_Measurement_Of_Ships.pageheading}
      pagedescription={service.Thickness_Measurement_Of_Ships.pagedescription}
     />
  </div>
  )
}
export default ThicknessMeasurementOfShips