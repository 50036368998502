import React from "react";
import prashant from "../../assests/images/banner/prashantgyan.jpg";
import piyush from "../../assests/images/banner/PIYUSH_KUMAR.jpg";
import hari from "../../assests/images/banner/Hari.jpg";
import gary from "../../assests/images/banner/Gary.jpg";
import shail from "../../assests/images/banner/shail.jpg";
import atanu from "../../assests/images/banner/atanu.jpg";
import Carousel from "react-multi-carousel";
const Team = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const TeamHead = [
    {
      name: "Prashant Gyan",
      img: prashant,
      designation: "Founder & Director",
      description: "Prashant Gyan is an accomplished entrepreneur with vast experience in shipping, engineering, and technology. He honed his expertise in marine engineering and ship repair while working for various companies before founding Shipsmith. Under his leadership, Shipsmith has grown into a leading provider of ship repair and marine engineering solutions, offering a wide range of services.",
    },
    {
      name: "Atanu Ghosh",
      img: atanu,
      designation: "Partner & Technical Head",
      description: "Atanu Ghosh, Chief Engineer at Shipsmith, brings over 16 years of maritime experience, focusing on drydock and delivery yard operations. He ensures vessels operate at peak performance and leads technical operations with a strong emphasis on quality and efficiency. By integrating advanced technologies and industry best practices, Atanu drives innovation and excellence, contributing to Shipsmith's success and client satisfaction.",
    },
    {
      name: "Hari Shankar",
      img: hari,
      designation: "Founder & Head of Operations",
      description: " Hari Shankar is a dynamic entrepreneur with extensive experience in diverse sectors, ranging from logistics and engineering to manufacturing and technology. With a deep-rooted passion for entrepreneurship, Hari has successfully managed and led various ventures throughout their career. Currently, as the Managing Director of Shipsmith, Hari oversees the operations and ensures the seamless execution of projects.",
    },
    {
      name: "Shailendra",
      img: shail,
      designation: "Partner & Head of Commercial",
      description: "Shailendra, Partner and Head of Commercial at Shipsmith, is a skilled communicator with a diverse background. He began as a marine engineer, sailing for 7+ years, then earned an MBA in Finance and Strategy. After 6+ years in retail strategy and consulting, he launched and ran two data analytics companies over the past five years. Shailendra excels in strategy, problem-solving, and managing rapid business growth.",
    },
  ];
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <h4 className="italic ">Our Team</h4>
        </div>
      </div>
      <div className="row ">
        <div className="col-sm-8 m-auto">
          <h2 className="keep  text-center" color="#000000">
            A group of highly skilled and experienced marine engineers and
            experts
          </h2>
          <p className="text-grey text-center">
            Providing premium ship repair and comprehensive solutions to clients
            globally.
          </p>
        </div>
      </div>
      <div className=" my-4">
        <Carousel
          responsive={responsive}
          arrows={false}
          className="text-center"
          showDots={false}
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite={true}
        >
          {TeamHead.map((val) => {
            return (
              <div
                className="p-3 mb-5 prs"
                data-aos="fade-left"
                data-aos-offset="50"
                data-aos-delay="50"
                data-aos-duration="600"
                data-aos-easing="ease-in-out"
                data-aos-mirror="false"
                data-aos-once="true"
                data-aos-anchor-placement="top"
              >
                <div className="row team_section shadow operat">
                  <div className="col-sm-4 col-lg-4 col-md-6 m-auto">
                    <img alt="" src={val.img} className="image" />
                  </div>
                  <div className="col-sm-8 col-lg-12 col-md-4 m-auto">
                    <h4 className="kum mt-3" color="#000000">
                      {val.name}
                    </h4>
                    <p className="f-13">{ val.designation}</p>

                    <p className="text-justify text-grey f-13  pras">
                     {val.description}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
};

export default Team;
